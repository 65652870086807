import "./PaymentSuccess.css";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Parse from "parse";

const PaymentSuccess: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      try {
        const bundlePay = localStorage.getItem("bundlePay");
        const petitionId = localStorage.getItem("petitionId") || "";
        if (bundlePay === "true") {
          setTimeout(() => {
            history.push("/create-petition");
          }, 3000);
        } else {
          const Petition = Parse.Object.extend("Petition");
          const query = new Parse.Query(Petition);
          const petition = await query.get(petitionId);
          petition.set("isPay", true);
          localStorage.setItem("notPaidPetitions", "1");
          await petition.save();
          setTimeout(() => {
            history.push("/petition-created");
          }, 3000);
        }
      } catch (error) {
        console.log("Dilekçe güncelleme hatası:", error);
      }
    };
    handlePaymentSuccess();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="container-success">
      <div className="content-success">
        <p className="label-success">ÖDEME İŞLEMİ BAŞARILI.</p>
        <p className="text-success">LÜTFEN BEKLEYİNİZ.</p>
        <div className="spinner"></div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
