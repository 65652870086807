import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  petitionData: "",
  preventNavigation:false,
  stripeIntent: {},
  dailyCreationLimit: 2,

}

export const slice = createSlice({
  name: 'petition',
  initialState,
  reducers: {
    setPetitionData: (state, action) => {
      state.petitionData = action.payload
    },
    setPreventNavigation: (state, action) => {
      state.preventNavigation = action.payload
    },
    setStripeIntent: (state, action) => {
      state.stripeIntent = action.payload
    },
    setDailyCreationLimit: (state, action) => {
      state.dailyCreationLimit = action.payload
    },
  },
})

export const { setPetitionData,setPreventNavigation, setStripeIntent,setDailyCreationLimit } = slice.actions

export default slice.reducer