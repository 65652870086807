/* eslint-disable react-hooks/exhaustive-deps */
import { loadStripe } from "@stripe/stripe-js";
import { getCurrentUser, getCreatePaymentIntent } from "../service/parse-service";
import { Elements } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import PetitionCreatedDemo from "./PetitionCreatedDemo";
import { useSelector, useDispatch } from "react-redux";
import { setStripeIntent } from "../store/slice";

const PetitionCreatedDemoWrapper: React.FC = () => {
  const dispatch = useDispatch();
  const { stripeIntent } = useSelector((state: any) => state.slice);
  const [intentOptions, setIntentOptions] = useState<any>(null);
  const appearance = {
    theme: "flat",
  };
  const stripePromise = loadStripe("pk_live_51Mqy2pLla1LthQRs0vif7VCj1wPHNUr4spMbNq1bypSllM98rx4TFmXj7RwGHp2QzaHc2q7bFOqIbiOAfVbICOmz00ymDrSlDA");

  useEffect(() => {
    if (Object.keys(stripeIntent).length !== 0) {
      setIntentOptions(stripeIntent);
      return;
    }
    const currentUser = getCurrentUser();
    const sessionToken = currentUser?.getSessionToken();
    getCreatePaymentIntent(sessionToken).then((data: any) => {
      const { client_secret } = data;
      setIntentOptions({
        // passing the client secret obtained from the server
        clientSecret: client_secret,
        appearance,
      });
    });
  }, []);

  useEffect(() => {
    if (intentOptions) {
      dispatch(setStripeIntent(intentOptions));
    }
  }, [intentOptions]);

  return (
    <>
      {intentOptions && (
        <Elements stripe={stripePromise} options={intentOptions}>
          <PetitionCreatedDemo />
        </Elements>
      )}
    </>
  );
};

export default PetitionCreatedDemoWrapper;
