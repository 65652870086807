import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  getCurrentUser,
  getCreatePaymentIntent,
} from "../service/parse-service";
// import CheckoutForm from '@/components/CheckoutForm'
import { setStripeIntent } from "../store/slice";
import { useSelector, useDispatch } from "react-redux";
import CheckoutForm from "./CheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  "pk_live_51Mqy2pLla1LthQRs0vif7VCj1wPHNUr4spMbNq1bypSllM98rx4TFmXj7RwGHp2QzaHc2q7bFOqIbiOAfVbICOmz00ymDrSlDA",
  { locale: "tr" }
);
interface PaymentProps {
  setShowStripeModal: (show: boolean) => void;
  type: string; // 'type' için daha spesifik bir tipiniz varsa onu kullanabilirsiniz.
}
const Payment = ({ setShowStripeModal, type }: PaymentProps) => {
  const { stripeIntent } = useSelector((state: any) => state.slice);
  const [intentOptions, setIntentOptions] = useState<any>(null);
  const dispatch = useDispatch();
  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#1E88E5",
      colorText: "#000000",
    },
  };
  useEffect(() => {
    if (Object.keys(stripeIntent).length !== 0) {
      setIntentOptions(stripeIntent);
      return;
    }
    const currentUser = getCurrentUser();
    const sessionToken = currentUser?.getSessionToken();
    getCreatePaymentIntent(sessionToken, type).then((data: any) => {
      const { client_secret } = data;
      setIntentOptions({
        // passing the client secret obtained from the server
        clientSecret: client_secret,
        appearance,
      });
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (intentOptions) {
      dispatch(setStripeIntent(intentOptions));
    }
    //eslint-disable-next-line
  }, [intentOptions]);

  return (
    <div className="payment-modal">
      {intentOptions && (
        <Elements stripe={stripePromise} options={intentOptions}>
          <CheckoutForm setShowStripeModal={setShowStripeModal} />
        </Elements>
      )}
    </div>
  );
};

export default Payment;
