import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./CreatePetition.css";
import { useHistory } from "react-router-dom";
import {
  generatePetitionNew,
  getAllPetitionCategories,
} from "../service/parse-service";
import { setPetitionData, setPreventNavigation } from "../store/slice";
import { IonAlert } from "@ionic/react";
import Parse from "parse";
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
} from "@ionic/react";
import Kvkk from "./Kvkk";
import ProductCard from "./ProductCard";
import { Capacitor } from "@capacitor/core";
import { useSelector } from 'react-redux'


const CreatePetition: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const infoIsOpen = localStorage.getItem("userInfo") ? true : false;
  const today = new Date().toISOString().split("T")[0];
  const { dailyCreationLimit } = useSelector((state: any) => state.slice);

  interface Object {
    [key: string]: string[];
  }

  const [loading, setLoading] = useState(false);
  const [kvkkIsOpen, setKvkkIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isProductCardOpen, setIsProductCardOpen] = useState(false)
  const [step, setStep] = useState(0);
  const [info, setInfo] = useState({
    category: "",
    subcategories: "",
    name: "",
    lastName: "",
    tc: "",
    reporter_address: "",
    target_name: "",
    target_tc_ops: "",
    target_address_ops: "",
    date: today,
    place: "",
    petition_addressee: "",
    tax_or_tc_no: "",
    addressee_address: "",
  });
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [subCategoryData, setSubCategoryData] = useState<any[]>([]);
  const [detailsPetition, setDetailsPetition] = useState({
    subject: "",
    details: "",
  });
  const [timer, setTimer] = useState(60);
  const [notPaidCount, setNotPaidCount] = useState(0)


  const categoryInputs: Object = {
    Aile: [
      "name",
      "lastName",
      "tc",
      "reporter_address",
      "target_tc_ops",
      "target_address_ops",
      "subject",
      "detail",
    ],
    Gayrimenkul: [
      "name",
      "lastName",
      "tc",
      "reporter_address",
      "target_name",
      "date",
      "place",
      "subject",
      "detail",
    ],
    Genel: [
      "name",
      "lastName",
      "tc",
      "reporter_address",
      "date",
      "place",
      "subject",
      "detail",
    ],
    İcra: [
      "name",
      "lastName",
      "tc",
      "reporter_address",
      "target_name",
      "target_tc_ops",
      "target_address_ops",
      "date",
      "place",
      "subject",
      "detail",
    ],
    İş: [
      "name",
      "lastName",
      "tc",
      "reporter_address",
      "target_name",
      "target_tc_ops",
      "target_address_ops",
      "date",
      "place",
      "subject",
      "detail",
    ],
    Noter: [
      "name",
      "lastName",
      "tc",
      "reporter_address",
      "target_name",
      "target_tc_ops",
      "target_address_ops",
      "date",
      "place",
      "subject",
      "detail",
    ],
    Trafik: [
      "name",
      "lastName",
      "tc",
      "reporter_address",
      "target_name",
      "date",
      "place",
      "subject",
      "detail",
    ],
    Vergi: [
      "name",
      "lastName",
      "tc",
      "reporter_address",
      "target_name",
      "date",
      "place",
      "subject",
      "detail",
    ],
  };

  useEffect(() => {
    getAllPetitionCategories().then((res) => {
      setCategoryData(res);
    });
  }, []);

  const inputList = categoryInputs[info.category];

  const handleCreatePetition = (e: any) => {
    e.preventDefault();
    setLoading(true);
    dispatch(setPreventNavigation(true));
    const platform = Capacitor.getPlatform();
    generatePetitionNew(info, detailsPetition, platform)
      .then((res) => {
        if (res?.id) {
          const interval = setInterval(() => {
            if (!res?.id) return;
            const query = new Parse.Query("Petition");
            query
              .equalTo("objectId", res?.id)
              .first()
              .then((response: any) => {
                if (response.get("status") === "completed") {
                  clearInterval(interval);
                  setLoading(false);
                  dispatch(setPreventNavigation(false));
                  const notPaidPetitions:any = localStorage.getItem('notPaidPetitions')
                  localStorage.setItem("petitionData",response.get("petition"));
                  localStorage.setItem("petitionId", response.id);
                  localStorage.setItem('notPaidPetitions', (parseInt(notPaidPetitions) + 1).toString())
                  const bundlePay = localStorage.getItem('bundlePay')

                  dispatch(setPetitionData(JSON.parse(response.get("petition"))));
                  if (bundlePay === 'true') {
                    const forBundlePay = async () => {
                      try {
                        let petitionId =
                          localStorage.getItem('petitionId') || ''
                        const Petition = Parse.Object.extend('Petition')
                        const query = new Parse.Query(Petition)
                        const petition = await query.get(petitionId)
                        petition.set('isPay', true)
                        await petition.save()
                        setTimeout(() => {
                          history.push('/petition-created')
                        }, 1000)
                      } catch (error) {
                        console.log('Dilekçe güncelleme hatası:', error)
                      }
                    }
                    forBundlePay()
                  } else {
                    history.push('/petition-created-demo')
                  }
                } else if (response.get("status") === "error") {
                  clearInterval(interval);
                  setLoading(false);
                  dispatch(setPreventNavigation(false));
                  setIsError(true);
                }
              });
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
        dispatch(setPreventNavigation(false));
      });
  };

  const handleStep = (e: any) => {
    e.preventDefault()
    if (notPaidCount >= dailyCreationLimit) {
      setIsProductCardOpen(true)
      localStorage.setItem('bundlePay', 'false')
    } else {
      setStep(step + 1)
      localStorage.setItem('userInfo', JSON.stringify(info))
    }
  };


  const handleChangeCategory = (e: any) => {
    const value = JSON.parse(e.target.value);
    setInfo({ ...info, category: value.category, subcategories: "" });
    setSubCategoryData(value.subcategories);
  };

  const handleChange = (e: any) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  const handleDetails = (e: any) => {
    setDetailsPetition({ ...detailsPetition, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (loading && timer > 0) {
      const timeOut = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timeOut);
    }
    // eslint-disable-next-line
  }, [loading, timer]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const notPaidPetitions:any = localStorage.getItem('notPaidPetitions')
      if (!notPaidPetitions) {
        localStorage.setItem('notPaidPetitions', '1')
      }
      setNotPaidCount(parseInt(notPaidPetitions))
      // if (parseInt(notPaidPetitions) >= dailyCreationLimit) {
      //   setIsProductCardOpen(true)

      // }
    }
    //eslint-disable-next-line
  }, [])

  return (
    <>
      {step === 0 && (
        <>
          <div className="container-create">
          {isProductCardOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center p-5">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="relative h-full w-full rounded-[8px] bg-white p-2 shadow-[10px_10px_0px_0px_rgba(0,0,0,1.75)] md:w-3/4 md:p-8 xl:w-2/4">
                  <div className="mb-4 flex items-center justify-end px-2 pt-2 md:p-0">
                    <button onClick={() => setIsProductCardOpen(false)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          d="M27.4417 4.55833C27.0841 4.20083 26.5991 4 26.0935 4C25.5878 4 25.1029 4.20083 24.7453 4.55833L16 13.3036L7.25475 4.55833C6.89714 4.20083 6.41219 4 5.90654 4C5.40088 4 4.91593 4.20083 4.55833 4.55833C4.20083 4.91593 4 5.40088 4 5.90654C4 6.41219 4.20083 6.89714 4.55833 7.25475L13.3036 16L4.55833 24.7453C4.20083 25.1029 4 25.5878 4 26.0935C4 26.5991 4.20083 27.0841 4.55833 27.4417C4.91593 27.7992 5.40088 28 5.90654 28C6.41219 28 6.89714 27.7992 7.25475 27.4417L16 18.6964L24.7453 27.4417C25.1029 27.7992 25.5878 28 26.0935 28C26.5991 28 27.0841 27.7992 27.4417 27.4417C27.7992 27.0841 28 26.5991 28 26.0935C28 25.5878 27.7992 25.1029 27.4417 24.7453L18.6964 16L27.4417 7.25475C27.7992 6.89714 28 6.41219 28 5.90654C28 5.40088 27.7992 4.91593 27.4417 4.55833Z"
                          fill="#2C2C30"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="my-2 h-[1px] w-full bg-[#D9D9D9]" />
                  <div className="overflow-y-auto">
                    <ProductCard />
                  </div>
                </div>
              </div>
            )}
            <IonAlert
              isOpen={infoIsOpen}
              header="Bilgilendirme"
              message="Kullanıcı bilgileriniz kaydedilmiştir , kullanmak ister misiniz?"
              buttons={[
                {
                  text: "Kullanma",
                  role: "cancel",
                  handler: () => {
                    localStorage.removeItem("userInfo");
                  },
                },
                {
                  text: "Kullan",
                  role: "confirm",
                  handler: () => {
                    setInfo(
                      JSON.parse(localStorage.getItem("userInfo") || "{}")
                    );
                  },
                },
              ]}
            ></IonAlert>
            <div className="content-create">
              <h1 className="label-create-center">
                ARZUHALCİ
                <br />
                PROFESYONEL DİLEKÇE YAZICI
              </h1>
              <h2 className="label-create">BİLGİLERİNİZ</h2>
              <form onSubmit={handleStep}>
                <select
                  defaultValue={info?.category}
                  onChange={(e) => handleChangeCategory(e)}
                  required
                  onInvalid={(e) =>
                    (e.target as HTMLInputElement).setCustomValidity(
                      "Lütfen bu alanı doldurun."
                    )
                  }
                  onInput={(e) =>
                    (e.target as HTMLInputElement).setCustomValidity("")
                  }
                  name="category"
                  className="petition-info-create"
                >
                  <option value="" disabled selected>
                    Kategori Seçiniz.
                  </option>
                  <option
                    value={JSON.stringify(
                      categoryData.find((item) => item.category === "Genel")
                    )}
                  >
                    Genel
                  </option>
                  {categoryData.map((data, index) => {
                    if (data?.category !== "Genel")
                      return (
                        <option key={index} value={JSON.stringify(data)}>
                          {data?.category}
                        </option>
                      );
                    return null;
                  })}
                </select>
                {subCategoryData.length > 0 && (
                  <select
                    defaultValue={info?.subcategories}
                    value={info?.subcategories}
                    onChange={(e) => handleChange(e)}
                    required
                    onInvalid={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Lütfen bu alanı doldurun."
                      )
                    }
                    onInput={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity("")
                    }
                    name="subcategories"
                    className="petition-info-create"
                  >
                    <option value="" disabled selected>
                      Alt Kategori Seçiniz.
                    </option>
                    {subCategoryData.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </select>
                )}
                {inputList?.includes("name") && (
                  <input
                    defaultValue={info?.name}
                    onChange={(e) => handleChange(e)}
                    required
                    onInvalid={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Lütfen bu alanı doldurun."
                      )
                    }
                    onInput={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity("")
                    }
                    name="name"
                    className="petition-info-create"
                    placeholder="İsminiz"
                  ></input>
                )}
                {inputList?.includes("lastName") && (
                  <input
                    defaultValue={info?.lastName}
                    onChange={(e) => handleChange(e)}
                    required
                    onInvalid={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Lütfen bu alanı doldurun."
                      )
                    }
                    onInput={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity("")
                    }
                    name="lastName"
                    className="petition-info-create"
                    placeholder="Soyadınız"
                  ></input>
                )}
                {inputList?.includes("tc") && (
                  <input
                    defaultValue={info?.tc}
                    onChange={(e) => handleChange(e)}
                    required
                    onInvalid={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Lütfen bu alanı doldurun."
                      )
                    }
                    onInput={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity("")
                    }
                    name="tc"
                    maxLength={11}
                    type="tel"
                    className="petition-info-create"
                    placeholder="TC Kimlik Numaranız"
                  ></input>
                )}

                {inputList?.includes("reporter_address") && (
                  <input
                    defaultValue={info?.reporter_address}
                    onChange={(e) => handleChange(e)}
                    required
                    onInvalid={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Lütfen bu alanı doldurun."
                      )
                    }
                    onInput={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity("")
                    }
                    name="reporter_address"
                    className="petition-info-create"
                    placeholder="Adresiniz"
                  ></input>
                )}
                {/* Opsiyonel */}
                {/* {inputList?.includes("target_name") && (
                <input
                  defaultValue={info?.target_name}
                  onChange={(e) => handleChange(e)}
                  required
                  name="target_name"
                  className="petition-info-create"
                  placeholder="Şikayet Edilen Kişi"
                ></input>
              )} */}
                {/* {inputList?.includes("target_tc_ops") && (
                <input
                  defaultValue={info?.target_tc_ops}
                  onChange={(e) => handleChange(e)}
                  name="target_tc_ops"
                  maxLength={11}
                  type="tel"
                  className="petition-info-create"
                  placeholder="Şikayet Edilen Kişinin TC Numarası (Opsiyonel)"
                ></input>
              )} */}
                {/* {inputList?.includes("target_address_ops") && (
                <input
                  defaultValue={info?.target_address_ops}
                  onChange={(e) => handleChange(e)}
                  name="target_address_ops"
                  className="petition-info-create"
                  placeholder="Şikayet Edilen Kişinin Adresi (Opsiyonel)"
                ></input>
              )} */}
                {/* {inputList?.includes("date") && (
                <>
                  <IonDatetimeButton className="datetime-button" datetime="datetime"></IonDatetimeButton>
                  <IonModal keepContentsMounted={true}>
                    <IonDatetime defaultValue={info?.date} presentation="date" onIonChange={(e) => handleChange(e)} name="date" id="datetime" showDefaultButtons doneText="Seç" cancelText="Kapat" locale="tr" firstDayOfWeek={1}></IonDatetime>
                  </IonModal>
                </>
              )} */}
                {inputList?.includes("date") && (
                  <input
                    defaultValue={info?.date}
                    onChange={(e) => handleChange(e)}
                    required
                    onInvalid={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Lütfen bu alanı doldurun."
                      )
                    }
                    onInput={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity("")
                    }
                    name="date"
                    type="date"
                    className="petition-info-create"
                    placeholder="Tarih"
                    lang="tr-TR"
                  ></input>
                )}
                {/* Opsiyonel */}
                {/* {inputList?.includes("place") && (
                  <input defaultValue={info?.place} onChange={(e) => handleChange(e)} required name="place" className="petition-info-create" placeholder="Yer"></input>
                )} */}
                <button type="submit" className="created-button-create">
                  İLERLE
                </button>
              </form>
            </div>
          </div>
        </>
      )}
      {/* {step === 1 && (
        <div className="container-create">
          <div className="content-create">
            <h3 className="label-create">Dilekçe Muhatabı</h3>
            <form onSubmit={handleStep}>
              <input
                onChange={(e) => handleChange(e)}
                required
                onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity("Lütfen bu alanı doldurun.")}
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity("")}
                name="petition_addressee"
                className="petition-info-create"
                placeholder="Örn:T.C. Devlet Demir Yolları"
              ></input>
              <input onChange={(e) => handleChange(e)} name="tax_or_tc_no" className="petition-info-create" placeholder="Vergi Numarası / TC Numarası (Opsiyonel)"></input>
              <input onChange={(e) => handleChange(e)} name="addressee_address" className="petition-info-create" placeholder="Muhatap Açık Adresi (Opsiyonel)"></input>
              <button type="submit" className="created-button-create">
                İLERLE
              </button>
            </form>
          </div>
        </div>
      )} */}
      {step === 1 && (
        <div className="container-create">
          <IonModal
            className="show-modal"
            isOpen={kvkkIsOpen}
            onDidDismiss={() => setKvkkIsOpen(false)}
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => setKvkkIsOpen(false)}>
                    Kapat
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <Kvkk />
            </IonContent>
          </IonModal>
          <IonAlert
            isOpen={isError}
            header="Hata"
            message="Dilekçe oluştururken bir hata oluştu. Lütfen tekrar deneyiniz."
            buttons={["Tamam"]}
            onDidDismiss={() => setIsError(false)}
          ></IonAlert>
          <div className="content-create">
            <h2 className="label-create">DİLEKÇE KONUSU</h2>
            <form onSubmit={handleCreatePetition}>
              <input
                onChange={(e) => handleDetails(e)}
                disabled={loading}
                required
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Lütfen bu alanı doldurun."
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
                name="subject"
                className="petition-info-create"
                placeholder="Dilekçe konusu"
              ></input>
              <textarea
                onChange={(e) => handleDetails(e)}
                disabled={loading}
                required
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Lütfen bu alanı doldurun."
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
                name="details"
                className="petition-subject-create"
                placeholder="Dilekçenizi buraya yazın. Ne kadar fazla detay verirseniz dilekçe kaliteniz o kadar artacaktır."
              ></textarea>
              <div className="kvkk-wrapper">
                <input
                  type="checkbox"
                  id="kvkk-checkbox"
                  name="kvkk"
                  required
                  onInvalid={(e) =>
                    (e.target as HTMLInputElement).setCustomValidity(
                      "Lütfen bu alanı doldurun."
                    )
                  }
                  onInput={(e) =>
                    (e.target as HTMLInputElement).setCustomValidity("")
                  }
                />
                <label
                  htmlFor="kvkk-checkbox"
                  className="petition-info-kvkk-label"
                >
                  <span onClick={() => setKvkkIsOpen(true)}>
                    KVKK Kurallarını
                  </span>{" "}
                  okudum ve kabul ediyorum.
                </label>
              </div>
              {loading ? (
                <div className="loading-wrapper">
                  <p style={{ color: "#0C376A" }}>{`${
                    timer === 55 || timer === 54 || timer === 53 || timer === 0
                      ? "Bu işlem 1-2 dakika sürebilir. Lütfen bekleyiniz."
                      : "Dilekçeniz Oluşturuluyor."
                  }`}</p>
                  <div className="dots">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                <button type="submit" className="created-button-create">
                  OLUŞTUR
                </button>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePetition;
