import { CheckIcon, ShoppingBagIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import "./ProductCard.css";
import Parse from "parse";
import Payment from "./Payment";
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import { useIonLoading } from "@ionic/react";

export default function ProductCard() {
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [showLoading, dismissLoading] = useIonLoading();
  const [tiers, setTiers] = useState([
    {
      name: "Ekonomix Paket",
      id: "discountPrice",
      href: "#",
      price: null,
      features: ["1 Dilekçe Oluşturma Hakkı"],
      mostPopular: true,
    },
  ]);
  const history = useHistory();

  useEffect(() => {
    async function fetchAmount() {
      try {
        const query = new Parse.Query("Configs");
        query.equalTo("name", "discountPrice");
        const result = await query.first();
        if (result) {
          setTiers([
            {
              ...tiers[0],
              price: (result?.get("amount") / 100) as any,
            },
          ]);
        }
      } catch (error) {
        console.error("Error fetching configs:", error);
      }
    }
    fetchAmount();
    //eslint-disable-next-line
  }, []);

  // const handleInAppPurchase = async () => {
  //   try {
  //     if (Capacitor.getPlatform() === "ios") {
  //       const productToBuy = await Purchases.getProducts({ productIdentifiers: ["arzuhalci_1"] });
  //       const purchaseResult = await Purchases.purchaseStoreProduct({ product: productToBuy.products[0] });
  //       if (typeof purchaseResult.customerInfo.entitlements.active["arzuhalci_1"] !== "undefined") {
  //         console.log("User has access to arzuhalci_1");
  //         //go to payment success page
  //         let bundlePay = localStorage.getItem("bundlePay");
  //           if (bundlePay === "false") {
  //             localStorage.setItem("bundlePay", "true");
  //             localStorage.setItem("notPaidPetitions", "1");
  //           }
  //           history.push("/payment-success");
  //       }
  //     } else if (Capacitor.getPlatform() === "android") {
  //       const productToBuy = await Purchases.getProducts({ productIdentifiers: ["arzuhalci_android"] });
  //       const purchaseResult = await Purchases.purchaseStoreProduct({ product: productToBuy.products[0] });
  //       if (typeof purchaseResult.customerInfo.entitlements.active["arzuhalci_android"] !== "undefined") {
  //         console.log("User has access to arzuhalci_android");
  //         //go to payment success page
  //         let bundlePay = localStorage.getItem("bundlePay");
  //           if (bundlePay === "false") {
  //             localStorage.setItem("bundlePay", "true");
  //             localStorage.setItem("notPaidPetitions", "1");
  //           }
  //           history.push("/payment-success");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   }
  // };

  const handleInAppPurchase = async () => {
    try {
      // Platform tespiti yapıyoruz.
      const platform = Capacitor.getPlatform();

      // Platforma özgü ürün tanımlayıcısını belirliyoruz.
      const productId = platform === "ios" ? "arzuhalci_2" : "arzuhalci_yeni";
      showLoading("Ödeme adımına geçiliyor, lütfen bekleyiniz...", 5000);
      const productToBuy = await Purchases.getProducts({ productIdentifiers: [productId] });
      const purchaseResult = await Purchases.purchaseStoreProduct({ product: productToBuy.products[0] });
      if (typeof purchaseResult.customerInfo.entitlements.active[productId] !== "undefined") {
        console.log(`User has access to ${productId}`);
        dismissLoading();
        handlePaymentSuccess();
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Ödeme başarılı işlemlerini bu fonksiyonda topluyoruz.
  const handlePaymentSuccess = () => {
    let bundlePay = localStorage.getItem("bundlePay");
    if (bundlePay === "false") {
      localStorage.setItem("bundlePay", "true");
      localStorage.setItem("notPaidPetitions", "1");
    }
    history.push("/payment-success");
  };

  useEffect(() => {
    (async function () {
      if (Capacitor.getPlatform() === "web") {
        return;
      } else {
        await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
        if (Capacitor.getPlatform() === "ios") {
          await Purchases.configure({
            apiKey: "appl_YDCMTVdkeUnyFVoMiAovpQbJyJy",
          });
        } else if (Capacitor.getPlatform() === "android") {
          await Purchases.configure({
            apiKey: "goog_GltRpKkNHkEOfHlYcSdMPINOQCd",
          });
        }
      }
    })();
  }, []);

  return (
    <>
      {showStripeModal ? (
        <div>
          <Payment type="discount" setShowStripeModal={setShowStripeModal} />
        </div>
      ) : (
        <div className="overflow-y-auto bg-white py-3 md:py-8">
          <div className="mx-auto max-w-7xl px-2 md:px-4">
            <div className="mx-auto max-w-4xl text-center">
              <p className="mt-5 text-xl font-bold tracking-tight text-gray-900 md:text-3xl">
                Limitiniz Doldu.
                <br />
                Başka bir dilekçe oluşturmak için indirimli paket alabilirsiniz.
              </p>
            </div>
            <div className="isolate mx-auto mt-24 flex items-center justify-center md:mt-12">
              {tiers.every((tier) => tier.price === null) ? (
                <div className="spinner-success"></div>
              ) : (
                <>
                  {tiers.map((tier) => (
                    <div key={tier.id} className={"product-popular flex cursor-pointer flex-col justify-between rounded-3xl bg-white p-5 ring-1 ring-indigo-200 xl:p-8"}>
                      <div>
                        <div className="flex items-center justify-center">
                          <img src={"/assets/images/product-image.png"} alt="product" style={{ width: "90px", height: "90px" }} />
                        </div>
                        <div className="flex items-center justify-between gap-x-4">
                          <h3 id={tier.id} className={"text-base font-semibold leading-6 text-indigo-600 md:text-lg md:leading-8"}>
                            {tier.name}
                          </h3>
                        </div>
                        <p className="mt-5 flex items-baseline gap-x-1 md:mt-6">
                          <span className="text-2xl font-bold tracking-tight text-gray-900 md:text-4xl">{tier.price} ₺</span>
                        </p>
                        <ul className="mt-5 space-y-3 text-sm leading-6 text-gray-600 md:mt-8">
                          {tier.features.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                              <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <button
                        onClick={() => {
                          if (Capacitor.getPlatform() === "web") {
                            console.log("web");
                            setShowStripeModal(true);
                          } else {
                            handleInAppPurchase();
                          }
                        }}
                        aria-describedby={tier.id}
                        className={
                          "mt-5 flex items-center justify-center rounded-md bg-indigo-600 px-3  py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:mt-8"
                        }
                      >
                        <ShoppingBagIcon className="h-6 w-5" />
                      </button>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
