import { Redirect, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  IonApp,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonList,
  IonProgressBar,
  setupIonicReact,
  IonIcon,
} from "@ionic/react";
import { pencilOutline, heartOutline, searchOutline } from "ionicons/icons";
import { IonReactRouter } from "@ionic/react-router";
import CreatePetition from "./pages/CreatePetition";
import MyPetitions from "./pages/MyPetitions";
import PetitionCreatedDemoWrapper from "./pages/PetitionCreatedDemoWrapper";
import PetitionCreatedOriginal from "./pages/PetitionCreatedOriginal";
import Help from "./pages/Help";
import PaymentSuccess from "./pages/PaymentSuccess";
import { initializeParse, isParseInitialized, getCurrentUser, anonymousLogin } from "./service/parse-service";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import { Capacitor } from "@capacitor/core";
// import { Deploy } from "cordova-plugin-ionic";
import { useSelector } from "react-redux";
import { AppTrackingTransparency, AppTrackingStatusResponse } from "capacitor-plugin-app-tracking-transparency";

import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

setupIonicReact();

// Parse.initialize(
//   "kG5bG41NyoHPRo1VWLc4UXvlIEMHDdNqO9Gqmm1c",
//   "T52pJWlkCsMYBBB9svjYaL5VVLPB5JB8BQxD8mLO",
//   "bUHoKycMWcDi69mtPdWcM9bSYf3cuIgarEn38BDl"
// );
// Parse.serverURL = "https://parseapi.back4app.com";

const App: React.FC = () => {
  // const [options, setOptions] = useState<any>(null);
  const [showLiveUpdates, setShowLiveUpdates] = useState(false);
  const [updateProgress, setUpdateProgress] = useState(0);
  const { preventNavigation } = useSelector((state: any) => state.slice);

  // const appearance = {
  //   theme: "flat",
  // };

  // const stripePromise = loadStripe("pk_live_51Mqy2pLla1LthQRs0vif7VCj1wPHNUr4spMbNq1bypSllM98rx4TFmXj7RwGHp2QzaHc2q7bFOqIbiOAfVbICOmz00ymDrSlDA");

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-1055849821";
    script1.async = true;

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-1055849821');
    `;

    const script3 = document.createElement("script");
    script3.src = "https://www.googletagmanager.com/gtag/js?id=AW-11297748637";
    script3.async = true;

    const script4 = document.createElement("script");
    script4.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-11297748637');
    `;

    const script5 = document.createElement("script");
    script5.src = "https://www.googletagmanager.com/gtag/js?id=G-2YGFZRVPHY";
    script5.async = true;

    const script6 = document.createElement("script");
    script6.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-2YGFZRVPHY');
    `;

    script6.src = "https://www.googletagmanager.com/gtag/js?id=G-2YGFZRVPHY";
    script6.async = true;

    document.head.appendChild(script1);
    document.head.appendChild(script2);

    document.head.appendChild(script3);
    document.head.appendChild(script4);
    document.head.appendChild(script5);
    document.head.appendChild(script6);

    //use tracking transperancy flows with methods
    getStatus().then((status: AppTrackingStatusResponse) => {
      if (status.status === "authorized") {
        console.log("AppTrackingTransparency authorized");
      } else if (status.status === "denied") {
        console.log("AppTrackingTransparency denied");
      } else if (status.status === "notDetermined") {
        requestPermission().then((status) => {
          if (status.status === "authorized") {
            console.log("AppTrackingTransparency authorized");
          } else if (status.status === "denied") {
            console.log("AppTrackingTransparency denied");
          } else if (status.status === "notDetermined") {
            console.log("AppTrackingTransparency not-determined");
          }
        });
      }
    });

    performManualUpdate();
    isParseInitialized();
    initializeParse();
    isParseInitialized();
    const currentUser = getCurrentUser();
    // const sessionToken = currentUser?.getSessionToken();
    // getCreatePaymentIntent(sessionToken).then((data: any) => {
    //   const { client_secret } = data;
    //   setOptions({
    //     // passing the client secret obtained from the server
    //     clientSecret: client_secret,
    //     appearance,
    //   });
    // });
    if (!currentUser) {
      anonymousLogin();
    }
    //eslint-disable-next-line
  }, []);

  async function performManualUpdate() {
    // if (Capacitor.getPlatform() === "web") {
    //   return false;
    // }
    // const update = await Deploy.checkForUpdate();
    // if (update.available) {
    //   setShowLiveUpdates(true);
    //   await Deploy.downloadUpdate((progress: any) => {
    //     console.log("Download progress", progress);
    //     setUpdateProgress(progress / 100);
    //   });
    //   await Deploy.extractUpdate((progress: any) => {
    //     console.log("Extract progress", progress);
    //     setUpdateProgress(progress / 100);
    //   });
    //   await Deploy.reloadApp();
    //   setShowLiveUpdates(false);
    //   setUpdateProgress(0);
    //   return true;
    // } else {
    //   return false;
    // }
  }

  async function getStatus() {
    const status: AppTrackingStatusResponse = await AppTrackingTransparency.getStatus();
    console.log("AppTrackingTransparency status:", status);
    return status;
  }

  async function requestPermission() {
    const status: AppTrackingStatusResponse = await AppTrackingTransparency.requestPermission();
    console.log("AppTrackingTransparency status:", status);
    return status;
  }

  return (
    <IonApp>
      {!showLiveUpdates && (
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/create-petition">
                <CreatePetition />
              </Route>
              <Route exact path="/my-petitions">
                <MyPetitions />
              </Route>
              <Route path="/petition-created-demo">
                <PetitionCreatedDemoWrapper />
              </Route>
              <Route path="/help">
                <Help />
              </Route>
              <Route exact path="/petition-created">
                <PetitionCreatedOriginal />
              </Route>
              <Route exact path="/">
                <Redirect to="/create-petition" />
              </Route>
              <Route exact path="/payment-success">
                <PaymentSuccess />
              </Route>
            </IonRouterOutlet>
            <IonTabBar className="tabs" slot="bottom">
              <IonTabButton className="tab-button" tab="create-petition" href="/create-petition" disabled={preventNavigation}>
                <IonIcon className="tab-image-ion" aria-hidden="true" icon={pencilOutline} />
                <IonLabel className="tab-label">DİLEKÇE YAZ</IonLabel>
              </IonTabButton>
              <IonTabButton className="tab-button" tab="my-petitions" href="/my-petitions" disabled={preventNavigation}>
                <IonIcon className="tab-image-ion" aria-hidden="true" icon={searchOutline} />
                <IonLabel className="tab-label">DİLEKÇELERİM</IonLabel>
              </IonTabButton>
              <IonTabButton className="tab-button" tab="help" href="/help" disabled={preventNavigation}>
                <IonIcon className="tab-image-ion" aria-hidden="true" icon={heartOutline} />
                <IonLabel className="tab-label">YARDIM</IonLabel>
              </IonTabButton>
              {/* <IonTabButton className='tab-button' tab="tab4" href="/">
                    <img className='tab-image' src='../assets/images/Chat.png' alt='Chat'></img>
                    <IonLabel className='tab-label'>ASİSTAN</IonLabel>
                  </IonTabButton> */}
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      )}
      {showLiveUpdates && (
        <div className="update-container" style={{ width: "100%", height: "100%", backgroundColor: "#f8fafd" }}>
          <IonGrid style={{ height: "100%" }}>
            <IonRow style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <IonCol size="12">
                <IonCard style={{ marginTop: "20px" }}>
                  <IonCardContent>
                    <IonList>
                      <IonLabel>
                        <h2>Yeni güncelleme bulundu</h2>
                        <p style={{ marginTop: "1rem" }}>
                          Uygulama güncelleniyor. Canlı güncellemeler uzun sürebilir. Lütfen uygulama güncellenirken uygulamayı kapatmayınız.
                        </p>
                      </IonLabel>
                      <IonProgressBar style={{ marginTop: "1rem" }} value={updateProgress}></IonProgressBar>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}
    </IonApp>
  );
};

export default App;
