// import Parse from "parse";

// Parse.initialize(
//     "kG5bG41NyoHPRo1VWLc4UXvlIEMHDdNqO9Gqmm1c",
//     "T52pJWlkCsMYBBB9svjYaL5VVLPB5JB8BQxD8mLO",
//     "bUHoKycMWcDi69mtPdWcM9bSYf3cuIgarEn38BDl"
// );
// Parse.serverURL = "https://parseapi.back4app.com";

import Parse from "parse";

export const initializeParse = () => {
  Parse.serverURL = "https://parseapi.back4app.com";
  Parse.initialize("kG5bG41NyoHPRo1VWLc4UXvlIEMHDdNqO9Gqmm1c", "bUHoKycMWcDi69mtPdWcM9bSYf3cuIgarEn38BDl");
};

export const isParseInitialized = () => {
  return Parse.applicationId !== undefined;
};

export const generatePetitionNew = async (info, detailsPetition, platform) => {
  return await Parse.Cloud.run("generatePetitionNew", { ...info, ...detailsPetition, platform});
};

export const getCurrentUser = () => {
  return Parse.User.current();
};

export const anonymousLogin = async () => {
  return await Parse.AnonymousUtils.logIn();
};

export const getCreatePaymentIntent = async (sessionToken, type) => {
  return await Parse.Cloud.run("createPaymentIntent", {
      sessionToken, ...(type && { type: type })
  });
};

export const getAllPetitionCategories = async (sessionToken) => {
  return await Parse.Cloud.run("getAllPetitionCategories", { sessionToken });
};

export const getPetitions = async (sessionToken) => {
  return await Parse.Cloud.run("getPetitions", { sessionToken });
};

export const getPetitionById = async (petitionId) => {
  //Create Parse Query From Petition Class
  const Petition = Parse.Object.extend("Petition");
  const query = new Parse.Query(Petition);
  //Get Petition By Id
  return await query.get(petitionId);
};

export const sendEmailWithPdf = async (email, petitionId) => {
  return await Parse.Cloud.run("sendEmailWithPdf", { email, petitionId });
};
