/* eslint-disable @typescript-eslint/no-unused-vars */
import "./PetitionCreatedOriginal.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Clipboard } from "@capacitor/clipboard";
import { IonAlert, IonIcon, useIonAlert } from "@ionic/react";
import { getPetitionById, sendEmailWithPdf } from "../service/parse-service";
import { useHistory } from "react-router-dom";
import { copyOutline, documentOutline, clipboardOutline, pencilOutline, mailUnreadOutline } from "ionicons/icons";

const PetitionCreatedOriginal: React.FC = () => {
  const petitionData: any = JSON.parse(localStorage.getItem("petitionData") || "");
  const { fullname, addressee, address, details } = petitionData;
  const [isCopied, setIsCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [infoIsOpen, setInfoIsOpen] = useState(false);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [emailSend, setEmailSend] = useState(false);
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const today = new Date().toLocaleDateString("tr-TR");

  const writeToClipboard = async () => {
    const textCopied: any = document.getElementById("text-copied")?.innerText;
    setIsCopied(true);
    await Clipboard.write({
      string: textCopied,
    });
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const handleDownload = async () => {
    // const currentUser = getCurrentUser();
    // const sessionToken = currentUser?.getSessionToken();
    // getPetitions(sessionToken).then((res) => {
    // const petition = res?.reverse()[0]?.get("file")._url;
    //   if (petition){
    //     window.open(petition, '_blank');
    //   }
    // }).catch((err) => {
    //   console.log(err.message)
    // })

    const petitionId = localStorage.getItem("petitionId") || "";
    getPetitionById(petitionId)
      .then((res) => {
        const petition = res?.get("file")._url;
        if (petition) {
          if (!window.open(petition)) {
            window.location.href = petition;
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleSend = async (email: any) => {
    const petitionId = localStorage.getItem("petitionId") || "";
    sendEmailWithPdf(email, petitionId)
      .then(() => {
        presentAlert({
          header: "Bilgilendirme",
          message: "Emailiniz gönderildi.",
          buttons: ["Tamam"],
        });
        setEmailSend(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.innerHTML = `
      gtag('event', 'conversion', {
        'send_to': 'AW-1055849821/nvOnCLnAjb0YEN36u_cD',
        'value': 1.0,
        'currency': 'TRY',
        'transaction_id': ''
      });
    `;

    document.head.appendChild(script);
    const originalPetitionsCreatedCount = localStorage.getItem("originalPetitionsCreatedCount");
    if (originalPetitionsCreatedCount) {
      localStorage.setItem("originalPetitionsCreatedCount", (parseInt(originalPetitionsCreatedCount) + 1).toString());
    } else {
      localStorage.setItem("originalPetitionsCreatedCount", "1");
    }

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="container-created-original">
      {emailSend === false ? (
        <IonAlert
          isOpen={sendEmailOpen}
          onDidDismiss={() => setSendEmailOpen(false)}
          header="Email adresinizi doğru yazdığınızdan emin olun. Bu bir kereye mahsus bir işlemdir."
          inputs={[
            {
              name: "email",
              type: "email",
              placeholder: "Email adresiniz",
            },
          ]}
          buttons={[
            {
              text: "Vazgeç",
              role: "cancel",
              handler: () => {
                setSendEmailOpen(false);
              },
            },
            {
              text: "Gönder",
              role: "confirm",
              handler: (data: any) => {
                handleSend(data.email);
              },
            },
          ]}
        />
      ) : (
        <IonAlert isOpen={sendEmailOpen} onDidDismiss={() => setSendEmailOpen(false)} header="Email gönderildi." buttons={["Tamam"]} />
      )}

      <IonAlert
        isOpen={isOpen}
        header="Önemli bir bilgi"
        message="Dilekçenizi değiştirmek istediğiniz kısıma tıklayarak değiştirebilirsiniz. Bu sadece yazıyı kopyalamak için geçerlidir."
        buttons={["Tamam"]}
        onDidDismiss={() => setIsOpen(false)}
      ></IonAlert>

      <IonAlert
        isOpen={infoIsOpen}
        header="Bilgilendirme"
        message="Oluşturduğunuz dilekçe kaybolacaktır. Devam etmek istiyor musunuz?"
        buttons={[
          {
            text: "Hayır",
            role: "cancel",
            handler: () => {
              setInfoIsOpen(false);
            },
          },
          {
            text: "Evet",
            role: "confirm",
            handler: () => {
              history.push("/create-petition");
            },
          },
        ]}
      ></IonAlert>

      <div className="content-created-original">
        <h1 className="label-created-original">DİLEKÇENİZ</h1>
        <div contentEditable={true} id="text-copied" className="petition-subject-created-original">
          <p className="text-header-original">{addressee}</p>
          <p className="text-details-original">{details}</p>
          <p className="text-right-original">{fullname}</p>
          <p className="text-right-original">{today}</p>
          <p className="text-right-original">İmza: ............</p>
          <p className="text-left-original">Adres: {address}</p>
        </div>
        <button onClick={handleDownload} className="button-download-original">
          <span className="button-text-original">PDF OLARAK AÇ</span>
          <IonIcon className="icon-original" color="white" aria-hidden="true" icon={documentOutline} />
        </button>
        <button onClick={() => setSendEmailOpen(true)} className="button-signature-original">
          <span className="button-text-original">PDF'İ MAİL OLARAK YOLLA</span>
          <IonIcon className="icon-original" color="white" aria-hidden="true" icon={mailUnreadOutline} />
        </button>
        {isCopied ? (
          <button className="button-signature-original">
            <span className="button-text-original">KOPYALANDI</span>
            <IonIcon className="icon-original" color="white" aria-hidden="true" icon={clipboardOutline} />
          </button>
        ) : (
          <button onClick={writeToClipboard} className="button-download-original">
            <span className="button-text-original">YAZIYI KOPYALA</span>
            <IonIcon className="icon-original" color="white" aria-hidden="true" icon={copyOutline} />
          </button>
        )}
        <button onClick={() => setInfoIsOpen(true)} className="button-signature-original">
          <span className="button-text-original">YENİ DİLEKÇE OLUŞTUR</span>
          <IonIcon className="icon-original" color="white" aria-hidden="true" icon={pencilOutline} />
        </button>
      </div>
    </div>
  );
};

export default PetitionCreatedOriginal;
