import "./MyPetitions.css";
import { useEffect, useState } from "react";
import { getPetitions, getCurrentUser } from "../service/parse-service";
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonIcon,
} from "@ionic/react";
import { eyeOutline, documentOutline } from "ionicons/icons";

const MyPetitions: React.FC = () => {
  const [myPetitions, setMyPetitions] = useState<any>(null);
  const [selectedPetition, setSelectedPetition] = useState<any>({
    fullname: "",
    subject: "",
    addressee: "",
    address: "",
    details: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleDownload = async (petition: any) => {
    if (myPetitions) {
      window.open(petition, "_blank");
    }
  };

  const openModal = (petition: any) => {
    if (myPetitions) {
      const today = new Date().toLocaleDateString("tr-TR");
      const { fullname, subject, addressee, address, details } = petition;
      setSelectedPetition({
        fullname,
        subject,
        addressee,
        today,
        address,
        details,
      });
      setIsOpen(true);
    }
  };

  useEffect(() => {
    const currentUser = getCurrentUser();
    const sessionToken = currentUser?.getSessionToken();
    getPetitions(sessionToken)
      .then((res) => {
        setMyPetitions(res.reverse());
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <>
      <IonModal
        className="show-modal"
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Dilekçeniz</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)}>Kapat</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div style={{ textAlign: "center" }}>
            <p className="text-header-original">
              {selectedPetition?.addressee}
            </p>
            <p className="text-details-original">{selectedPetition?.details}</p>
            <p className="text-right-original">{selectedPetition?.fullname}</p>
            <p className="text-right-original">{selectedPetition?.today}</p>
            <p className="text-right-original">İmza: ............</p>
            <p className="text-left-original">
              Adres: {selectedPetition?.address}
            </p>
          </div>
        </IonContent>
      </IonModal>

      <div className="container-my-petitions">
        <div
          className={`${
            !myPetitions ? "contents-not-my-petitions" : "contents-my-petitions"
          }`}
        >
          {!myPetitions ? (
            <div className="spinner-my-petitions"></div>
          ) : (
            <>
              <h1 className="label-my-petitions">DİLEKÇELERİNİZ</h1>
              <div className="header-my-petitions">
                <span>BAŞLIK / TARİH</span>
                <span>GÖRÜNTÜLE / PDF</span>
              </div>
              <ul className="list-ul">
                {myPetitions?.length > 0 &&
                  myPetitions.map((petition: any, index: any) => {
                    if (petition?.get("isPay") === true) {
                      return (
                        <li className="list-item" key={index}>
                          <span className="list-text-left">
                            {petition?.get("params").subject} /{" "}
                            {petition?.get("createdAt").toLocaleDateString()}
                          </span>
                          <div className="see-pdf-my-petitions">
                            <IonIcon
                              className="icon-my-petitions"
                              onClick={() =>
                                openModal(JSON.parse(petition.get("petition")))
                              }
                              aria-hidden="true"
                              icon={eyeOutline}
                            />
                            <IonIcon
                              className="icon-my-petitions"
                              onClick={() =>
                                handleDownload(petition?.get("file")._url)
                              }
                              aria-hidden="true"
                              icon={documentOutline}
                            />
                          </div>
                        </li>
                      );
                    }
                    return null;
                  })}

                {myPetitions?.length === 0 && (
                  <li className="list-item">
                    <span className="list-text-left">
                      Henüz oluşturduğunuz dilekçeniz yok.
                    </span>
                  </li>
                )}

                {myPetitions?.length > 0 &&
                  myPetitions.every(
                    (petition: any) => petition?.get("isPay") !== true
                  ) && (
                    <li className="list-item">
                      <span className="list-text-left">
                        Henüz satın aldığınız bir dilekçe bulunmamakta.
                      </span>
                    </li>
                  )}
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MyPetitions;
