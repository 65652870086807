import './Disclaimer.css';

const Disclaimer: React.FC = () => {

    return (
        <div className='container-disclaimer'>
            <div className='content-disclaimer'>
                <h2 className='label-disclaimer'>SORUMLULUK REDDİ BEYANI</h2>
                <div className='petition-subject-disclaimer'>
                    <strong>Arzuhalci</strong> uygulamasının verdiği hizmet, kullanıcının verdiği bilgiler doğrultusunda ilgili makama dilekçe hazırlamaktır.<strong>Arzuhalci </strong>uygulaması bu hizmeti tam otomatik olarak ve yapay zekâ destekli alt yapı ile gerçekleştirmektedir. Bu anlamda uygulamanın verdiği hizmetin hiçbir aşamasında (yazılım oluşturma ve geliştirme hariç) insan müdahalesi söz konusu değildir. Bu nedenle uygulama tarafından önerilen dilekçeleri “taslak” olarak değerlendirmek gerekir. Arzuhalciyi geliştiren şirket girilen bilgiler sonucunda oluşturulan dilekçenin doğruluğunu, hukuka uygunluğunu, doğru makama yazıldığını… vb. garanti etmemektedir. Uygulamayı kullanan kişiler, gerektiğinde yaşadıkları problemin ve dilekçe yazma ihtiyacının niteliğine uygun olarak avukata başvurmak zorunda olduklarını bilmelidirler. Uygulamayı geliştiren şirket yazılan dilekçelerin neden olabileceği olumlu ya da olumsuz hiçbir hukuki veya cezai sorumluluğu kabul etmemektedir. Uygulamanın hazırlayacağı taslak dilekçeler nedeniyle doğacak zararlardan uygulamayı geliştiren şirket sorumlu tutulamaz ve uygulamayı yazan şirketten uygulamanın hazırladığı taslak dilekçe nedeniyle herhangi bir tazminat talep edilemez. Hukuki danışmanlık hizmeti Türk Yasalarına göre yalnızca avukatlarca verilebilen bir hizmettir. İşbu uygulamanın hukuki danışmanlık hizmeti vermek gibi bir görevi ve amacı yoktur. Dilekçe içeriğinde yer alabilecek hukuki ifadeler ve yasa maddelerine yapılan atıflar hukuki danışmanlık kapsamında değerlendirilemez, doğruluğu garanti edilemez.
                    <br />
                    <br />
                    Uygulamanın yazımını gerçekleştireceği taslak dilekçelerde yer alabilecek olası hatalar nedeniyle başvuru yapılan kurum, kuruluş, gerçek veya tüzel kişiler nezdinde birtakım hukuki sonuçlar doğabileceği, hakkın doğumu için sürelerin başlayabileceği, verilen dilekçelerin hukuksal olarak bağlayıcı sonuçlar doğurabileceği, bir hakkın doğumu ya da sona ermesi sağlayabileceği gibi hak kaybına da sebep olabileceği, kullanılan ifadelerin geri dönülemez bir biçimde bağlayıcı olabileceği bilinmelidir. Bu nedenle dilekçeleri ilgili makamlara sunmadan önce bir avukatla iletişime geçmeniz şirket tarafından şiddetle tavsiye edilmektedir. Uygulama tarafından hazırlanacak taslak bir dilekçenin beklenen sonucu doğurmaması yalnız ve ancak uygulama kullanıcısının sorumluluğundadır. Bu nedenle beklenmeyen sonuçlar nedeniyle şirketten hukuki veya cezai hiçbir hak ve talepte bulunulamaz.
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
};

export default Disclaimer;
