import './Kvkk.css';

const Kvkk: React.FC = () => {

    return (
        <div className='container-kvkk'>
            <div className='content-kvkk'>
                <h2 className='label-kvkk'>KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI
                    KVKK Aydınlatma Metni</h2>
                <div className='petition-subject-kvkk'>
                    <p className='text-center-kvkk'>
                        <strong>Arzuhalci </strong>
                        tarafından gizliliğinize saygı duyulmakta ve veri güvenliğinize önem verilmektedir. Bu kapsamda 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) ve ilgili diğer mevzuat dâhilinde sizleri bilgilendirmek ve aydınlatmak amacıyla işbu metin hazırlanmıştır.
                    </p>
                    <br />

                    <strong>Bilgilendirme</strong>
                    <br />
                    <br />
                    6698 sayılı Kişisel Verilerin Korunması Kanunu 24 Mart 2016 tarihinde kabul edilmiş olup 7 Nisan 2016 tarihli Resmî Gazete’de yayımlanarak yürürlüğe girmiştir.
                    <br />
                    <br />
                    Kanun, kişisel verilerin işlenmesinde başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verileri işleyen gerçek ve tüzel kişilerin yükümlülükleri ile uyacakları usul ve esasları düzenlemek amacıyla kabul edilmiştir.
                    <br />
                    <br />
                    İşbu metin 6698 sayılı Kişisel Verilerin Korunması Kanunu’ndan doğan aydınlatma yükümlülüğümüzü yerine getirmek amacıyla hazırlanmıştır ve Arzuhalci uygulamasında yer alan “KVKK kurallarını okudum ve kabul ediyorum.” ifadesinin yanında bulunan kutucuğun tıklanması işbu metnin tam olarak okunduğu ve anlaşıldığı anlamına gelecektir. Kutucuğu tıklayan kişi bundan sonra KVKK bilgilendirmesi yapılmadığını ya da kişisel verilerin işlenmesine onayı olmadığını savunamayacaktır.
                    <br />
                    <br />
                    <strong>Arzuhalci</strong> uygulamasının yazılımı ve uygulamanın işlevini gerçekleştirmesini sağlayan yapay zekâ, birtakım bilgilerin girilmesini zorunlu kılmaktadır. Bu bilgilerin bir kısmı size ait kişisel veriler ve bir kısmı da muhatap olarak gösterdiğiniz gerçek veya tüzel kişilerle ilgilidir. Arzuhalci verilen bilgilerin doğruluğunu araştırmaz ve değerlendirmez. Uygulama verilen bilgiler hatalı olsa da işlevini yerine getirecektir. Bu nedenle bazı bilgilerin girilmesinin istenmesi Arzuhalci uygulamasının sahibi olan <strong>DE MARKE LABS TEKNOLOJİ VE YAZILIM TİC. LTD. ŞTİ</strong>’nin bunları kullanacağı anlamına gelmez. Uygulamanın sahibi olan şirket girilen bilgileri ve kişisel verileri üçüncü kişilere satmamayı, kiralamamayı veya hiçbir şekilde kullandırmamayı kabul, beyan ve taahhüt eder.
                    <br />
                    <br />
                    <strong>Kişisel Verilerinizin İşlenmesi</strong>
                    <br />
                    <br />
                    Kişisel verinin tanımı 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 3 (d) maddesinde yapılmıştır ve “kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi” ifade etmektedir.
                    <br />
                    <br />
                    Kişisel verilerinizin işlenmesi ile kastedilen ise 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 3 (e) maddesinde tanımlandığı şekilde kişisel verilerinizin tamamen veya kısmen elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemdir.
                    <br />
                    <br />
                    <strong>Veri Sorumlusu ve Temsilci</strong>
                    <br />
                    <br />
                    Kişisel verileriniz <strong>DE MARKE LABS TEKNOLOJİ VE YAZILIM TİC. LTD. ŞTİ.</strong> tarafından bu metinde açıklanan kapsamda işlenebilecektir. <strong>DE MARKE LABS TEKNOLOJİ VE YAZILIM TİC. LTD. ŞTİ.</strong> veri tabanında kayıtlı olan kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyerek, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumludur. Şirket, veri sorumluları siciline veri sorumlusu kaydını gerçekleştirerek kayıtlı veri sorumlusu sıfatına haiz olacağını beyan eder.
                    <br />
                    <br />
                    <strong>Verilerinizi İşleme Nedenlerimiz</strong>
                    <br />
                    <br />
                    Uygulamanın işlevini gerçekleştirebilmesi kişisel veriler olan ad, soyadı, T.C. kimlik numarası gibi bazı bilgilerin girilmesini gerektiriyorsa da uygulamaya bu bilgilerin hatalı girilmesi uygulamanın işlevini bozmamaktadır. Dilekçenin oluşturulması sırasında girilmesi gereken bu bilgiler kayıt altına alınmamakta ve saklanmamaktadır. Ancak çok sayıda dilekçenin yazılması gereken durumlarda her seferinde bu bilgilerin girilmesinin zorluğu nedeniyle bu bilgileri sonradan daha kolay girmek için uygulama üzerinde saklayabilirsiniz.
                    <br />
                    <br />
                    Uygulamanın dilekçeyi yazabilmesi için dilekçe içeriğine ilişkin olay yeri, tarihi, muhatap gibi bilgilerin girilmesine ihtiyaç vardır. Bu bilgilerdeki özel bölümlerin hatalı girilmesi de sonuca etkili değildir. Dilekçenin yazımı olayların doğru girilmesine bağlıdır. Bu anlamda gizli tutmak istediğiniz çok özel bilgileri hatalı bir biçimde giriş yapabilirsiniz, sonuç değişmeyecektir.
                    <br />
                    <br />
                    Uygulamanın geliştirilmesi, hataların düzeltilmesi, ürün kalitesinin yükseltilmesi… vb. gerekçelerle şirketimiz uygulamaya girilen kişisel verileri 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 4. 5. ve 6. maddeleri uyarınca işleyecek ve kullanacaktır.
                    <br />
                    <br />
                    <strong>Kişisel verileriniz, Arzuhalci tarafından, aşağıdaki hallerde ayrıca bir açık rızanız aranmaksızın işlenebilecektir:</strong>
                    <br />
                    <br />
                    <ul className='ul-kvkk'>
                        <li>
                            Uygulamanın girişinde yer alan “KVKK kurallarını okudum ve kabul ediyorum.” ifadesinin yanında bulunan kutucuğun tıklanması son kullanıcının uygulamaya girdiği her verinin Arzuhalci tarafından işlenebileceği anlamına gelir. Şirketin, kişisel verilerin ya da özel nitelikli kişisel verilerin işlenmesi için bu tıklama işlemi dışında herhangi bir izin ya da onay almasına gerek bulunmamaktadır.
                        </li>
                        <li>
                            Uygulamanın girişinde yer alan “KVKK kurallarını okudum ve kabul ediyorum.” ifadesinin yanında bulunan kutucuğun tıklanması, uygulamayı kullanan kişinin fiilî imkânsızlık nedeniyle rızasını açıklayamayacak kişilerden olmadığı, reşit olduğu, iradesine hukukî geçerlilik tanınan kişilerden olduğu anlamına gelecektir.
                        </li>
                        <li>Şirket uygulamaya girilmiş her bilgiyi yetkili idari otoritelerin ve yargı makamlarının yasaya uygun bildirimleri nedeniyle söz konusu ilgili makamlar, idari otoriteler ve yargı makamları ile paylaşabilir. Bu amaçla verileri derleyebilir, toplayabilir, sınıflandırabilir.</li>
                        <li>Uygulamaya girilmiş kişisel veriler, veriyi giren kişinin kendisinin ya da bir başkasının hayatının ya da bedensel bütünlüğünün korunması amacıyla başkaca bir onay almaya ihtiyaç olmaksızın işlenebilecek ve üçüncü kişilerle paylaşılabilecektir. Bu paylaşımın zorunlu olup olmadığına karar verme hak ve yetkisi şirkettedir.</li>
                        <li>Uygulamayı kullanan her kişi uygulamaya girdiği kişisel verilerin yapay zekayı geliştiren şirkete gönderilmesine onay vermiş kabul edilecektir. Bu nedenle uygulamayı kullananlar yapay zekayı üreten şirketin kişisel verilerle ilgili düzenlemelerini de kabul etmiş olmaktadırlar.</li>
                        <li>Uygulamayı kullanan kişinin kişisel verilerinin kendisi tarafından yayınlanmış ve alenileştirilmiş olması durumunda şirket elde ettiği verileri aynı kapsamda alenileştirme hak ve yetkisine sahip olacaktır.</li>
                        <li>Uygulamaya veri giren kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için kişisel veriyi işlenmesinin zorunlu olması halinde şirket elindeki verileri aşağıda belirteceğimiz amaçlarla kullanabilecektir.</li>

                    </ul>
                    <br />
                    <br />
                    <strong>Kişisel verileriniz şu amaçlarla işlenebilecektir:</strong>
                    <br />
                    <br />
                    <ul className='ul-kvkk'>
                        <li>Uygulamaya girdiğiniz kişisel verileri şirket uygulamanın kullanım amacının bir parçası olarak sizinle ve başkalarıyla iletişim kurmak amacıyla işleyebilir ve üçüncü kişilerle paylaşabilir.</li>
                        <li>Uygulamaya girilen her veri şirketin uygulamayı geliştirmesi, hatalarını tespit etmesi ve hataları düzeltmesi amacıyla işlenecektir. Bu işlemenin nasıl olacağına şirket tek taraflı bir biçimde karar verme hak ve yetkisine sahiptir. İşleme sırasında uygulamaya girilen kişisel veriler ve özel nitelikli kişisel verilerin işin mahiyetine uygun bir biçimde üçüncü kişilerle paylaşılması gerekebilir.</li>
                        <li>Uygulamaya girilen veriler istatistiksel veri elde etmek ve bu verileri analiz etmek amacıyla işlenebilir.</li>
                        <li>Şirket uygulamanın hizmet şartlarındaki değişiklikleri ve KVKK metnindeki değişiklikleri, şirket ile ilgili verileri ve sair önemli idari bilgileri duyurmak amacıyla kişisel verileri işleyebilecektir.</li>
                        <li>Şirket ileride uygulamaya ekleyeceği ek hizmetler ve yardım modüllerinin işlevsel olması amacıyla uygulamaya veri girişi yapan kişisel verilerini işleyebilecek, uygulama ile ilgili şikâyetleri çözmek ve veri erişim veya düzeltme taleplerini işleme almak için verileri kullanabilecektir.</li>
                        <li>Kara para aklamayı önleme ve terör karşıtı yasalar da dâhil olmak üzere, yürürlükteki yasalara ve düzenleyici yükümlülüklere (ikamet ülkenizin dışındakiler de dâhil) uymak, hukuki sürece uymak ve resmi makamlardan ve devlet makamlarından (ikamet ülkenizin dışındakiler de dâhil) gelen talepleri yanıtlamak,</li>
                        <li>Altyapımızı ve ticari faaliyetlerimizi yönetmek ve denetim, finans ve muhasebe, faturalama ve tahsilatlar, bilgi işlem sistemleri, veriler ve internet sitesi barındırma, iş devamlılığı ve kayıtlar, belge ve baskı yönetimi ile bağlantılı olanlar da dâhil olmak üzere, iç politika ve prosedürlere uymak,</li>
                        <li>Yasal hakları belirlemek ve bunları savunmak; faaliyetlerimizi veya iş ortaklarımızın faaliyetlerini, haklarımızı, gizliliğimizi, güvenliğimizi veya malımızı ve/veya sizin veya başkalarının bu sayılan varlıklarını korumak ve kullanılabilir çareleri uygulamak veya zararımızı sınırlandırmak,</li>
                        <li>Memnuniyet anketleri de dâhil olmak üzere, pazar araştırması ve analizi yürütmek,</li>
                        <li>Sosyal medya paylaşım fonksiyonunu kolaylaştırmak,</li>
                        <li>Size özel bilgiler ve reklamlar sunarak elektronik hizmetler ile ilgili deneyiminizi kişiselleştirmek.</li>
                    </ul>
                    <br />
                    <br />
                    <strong>İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</strong>
                    <br />
                    <br />
                    Toplanan kişisel verileriniz Şirketimiz ve Şirketimizle iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini, Şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması, Şirketimiz tarafından sunulan ürün ve hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi, Şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması ile Şirketimizin insan kaynakları politikalarının yürütülmesinin temini amaçlarıyla iş ortaklarımıza, tedarikçilerimize, hissedarlarımıza, kanunen yetkili kamu kurumları ve özel kişilere, KVK Kanunu’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.
                    <br />
                    <br />
                    <strong>Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</strong>
                    <br />
                    <br />
                    Kişisel verileriniz Şirketimiz tarafından farklı kanallarla ve farklı hukuki sebeplere dayanarak ticari faaliyetlerimizi yürütmek amacıyla toplanmaktadır. Bu hukuki sebeple toplanan kişisel verileriniz KVK Kanunu’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında bu metnin (b) ve (c) maddelerinde belirtilen amaçlarla da işlenebilmekte ve aktarılabilmektedir.
                    <br />
                    <br />
                    Kişisel Veri Sahibinin KVK Kanunu’nun 11. maddesinde sayılan hakları, Kişisel Veri Sahipleri olarak haklarınıza ilişkin taleplerinizi işbu Aydınlatma Metninde aşağıda düzenlenen yöntemlerle şirketimize iletmeniz durumunda şirketimiz talebin niteliğine göre talebi en kısa sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, Kişisel Verileri Koruma Kurulunca bir ücret öngörülmesi halinde, şirketimiz tarafından belirlenen tarifedeki ücret alınacaktır. Bu kapsamda kişisel veri sahipleri;
                    <br />
                    <ul className='ul-kvkk'>
                        <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                        <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                        <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                        <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                        <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                        <li>KVK Kanunu’nun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                        <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                        <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahiptir.</li>
                    </ul>
                    <br />
                    <br />
                    KVK Kanunu’nun 13. maddesinin 1. fıkrası gereğince, yukarıda belirtilen haklarınızı kullanmak ile ilgili talebinizi, yazılı veya Kişisel Verileri Koruma Kurulu’nun belirlediği diğer yöntemlerle şirketimize iletebilirsiniz. Kişisel Verileri Koruma Kurulu şu aşamada herhangi bir yöntem belirlemediği için, başvurunuzu KVK Kanunu gereğince yazılı olarak şirketimize iletmeniz gerekmektedir. Bu çerçevede şirketimize KVK Kanunu’nun 11. maddesi kapsamında yapacağınız başvurularda yazılı olarak başvurunuzu ileteceğiniz kanallar ve usuller aşağıda açıklanmaktadır.
                    <br />
                    <br />
                    Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit edici gerekli bilgiler ile KVK Kanunu’nun 11. maddesinde belirtilen haklardan kullanmayı talep ettiğiniz hakkınıza yönelik açıklamalarınızı içeren talebinizin imzalı bir nüshasını <strong>DE MARKE LABS TEKNOLOJİ VE YAZILIM TİC. LTD. ŞTİ.</strong> Lalebahçe Sokak Çankaya Apartmanı No:11/1 Merter / İSTANBUL adresine kimliğinizi tespit edici belgeler ile bizzat elden iletebilir, noter kanalıyla veya KVK Kanunu’nda belirtilen diğer yöntemler ile gönderebilir veya ilgili formu  <strong><a href="mailto:info@demarkelabs.com">info@demarkelabs.com </a></strong>adresine iletebilirsiniz.
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
};

export default Kvkk;
