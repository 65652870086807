import "./PetitionCreatedDemo.css";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { IonButton, IonAlert } from "@ionic/react";
import { StripePaymentElementOptions } from "@stripe/stripe-js";

type CheckoutFormProps = {
  setShowStripeModal: (show: boolean) => void;
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({ setShowStripeModal }) => {
  const [isError, setIsError] = useState(false);
  const [paymentErrorText, setPaymentErrorText] = useState<any>(null);
  const stripe = useStripe();
  const elements = useElements();

  const options: StripePaymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  };

  const handlePayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success`,
      },
    });
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setPaymentErrorText(result.error.message);
      setIsError(true);
    } else {
      let bundlePay = localStorage.getItem("bundlePay");
      if (bundlePay === "false") {
        localStorage.setItem("bundlePay", "true");
        localStorage.setItem("notPaidPetitions", "1");
      }
    }
  };

  return (
    <>
      <IonAlert
        isOpen={isError}
        header="Hata"
        message={`${
          paymentErrorText
            ? paymentErrorText
            : "Ödeme işlemi başarısız oldu. Lütfen tekrar deneyiniz."
        }`}
        buttons={["Tamam"]}
        onDidDismiss={() => setIsError(false)}
      ></IonAlert>

      <div>
        <PaymentElement className="card-info-create" options={options} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IonButton
            onClick={() => setShowStripeModal(false)}
            color="medium"
            className="text-center-button-demo-payment "
            size="small"
          >
            Vazgeç
          </IonButton>
          <div className="stripe-connect" onClick={handlePayment}>
            <span>İle Öde</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <img
            className="stripe-badge-image"
            src="../assets/images/stripe-badge-grey.png"
            style={{ width: "40%", marginTop: "8px" }}
            alt="stripe-powered"
          />
        </div>
      </div>
    </>
  );
};

export default CheckoutForm;
