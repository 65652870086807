import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react';
import './Help.css';

const Help: React.FC = () => {
  return (
    <div className='container-help'>
      <div className='content-help'>
        <h1 className='label-help'>ÖRNEK DİLEKÇE ŞABLONU</h1>
        <div className='petition-subject-help'>
          <p className='text-center-help'>Sayın Cumhuriyet Savcılığına,</p><br />

          Ben <strong>/İsminiz/</strong>, TC Numaram <strong>/TC Kimlik Numaranız/</strong> ve <strong>/Adresiniz/</strong> adresinde ikamet ediyorum. Bu dilekçe ile <strong>/Şikayetçi olduğunuz kişi/</strong> isimli şahsın bana karşı işlediği suçlar hakkında şikayetçi olduğumu bildirmek istiyorum.<br /><br />

          Olay, <strong>/Tarih/</strong> tarihinde <strong>/Olay yeri/</strong>'de gerçekleşti. Ben işten çıktım ve arabaya bindim eve dönüyordum. Birden bir araba bana çarptı ve ben arabadan indim. Tartışma çıktı ve vuran sürücü haklı olmama rağmen beni suçladı ve küfür etmeye başladı. Ben itiraz ettim ve o da saldırdı.<br /><br />

          Bu durumda, <strong>/Şikayetçi olduğunuz kişi/</strong>'ın bana karşı işlediği suçlar aşağıdaki gibidir:<br /><br />

          1. Trafik kazası sonrası kavgaya girişmek<br />
          2. Küfür etmek<br />
          3. Saldırmak<br /><br />

          Yukarıda belirttiğim sebeplerden dolayı, <strong>/Şikayetçi olduğunuz kişi/</strong> hakkında yasal işlem yapılmasını talep ediyorum. Konuyla ilgili olarak gereken adli sürecin başlatılmasını rica ederim.<br /><br />

          Saygılarımla.<br />
          <strong>/İsminiz/ </strong>
        </div>
        <h2 className='label-help'>SIKÇA SORULAN SORULAR</h2>
        <IonAccordionGroup className='accordion-container-help' expand="inset">
          <IonAccordion value="first">
            <IonItem className='accordion-button-help' slot="header" color="light">
              <IonLabel className='accordion-button-label'>Dilekçe nedir?</IonLabel>
            </IonItem>
            <div className='accordion-content-help' slot="content">
              Dilekçe; Bir istekte veya şikâyette bulunmak, herhangi bir konuda bilgi vermek veya bilgi talep etmek için resmî veya özel kurum ve kuruluşlara, gerçek ve tüzel kişilere yazılan başvuru belgesidir.
            </div>
          </IonAccordion>
          <IonAccordion value="second">
            <IonItem className='accordion-button-help' slot="header" color="light">
              <IonLabel className='accordion-button-label'>Dilekçenin yazılacağı kişiyi/makamı <br/> yanlış yazarsak ne olur?</IonLabel>
            </IonItem>
            <div className='accordion-content-help' slot="content">
              Dilekçe doğru makama hitaben yazılmalıdır. Yanlış makama yazılmış dilekçeyi dilekçenin verildiği makam doğru makama yönlendirmez. Dilekçeniz reddedilebilir ve bu da hak kaybına neden olabilir.
            </div>
          </IonAccordion>
          <IonAccordion value="third">
            <IonItem className='accordion-button-help' slot="header" color="light">
              <IonLabel className='accordion-button-label'>Dilekçede bulunması <br/> zorunlu unsurlar nelerdir?</IonLabel>
            </IonItem>
            <div className='accordion-content-help' slot="content">
              Dilekçeyi veren kişi dilekçesine kendisiyle ilgili bilgileri doğru yazmak zorundadır. Devlet kurumlarına verilecek bir dilekçede kişinin adı (varsa göbek adı) soyadı, T.C. Kimlik numarası mutlaka bulunmalıdır ve dilekçe ıslak imza ile imzalanmalıdır.
              <br />
              <br />
              Tarih atmak zorunlu olmasa da tarih atılması bazı karışıklıkları önleyeceği için yararlıdır.
              <br />
              <br />
              Dilekçeyi veren kişinin adresinin de dilekçede bulunması işlerin doğru ve hızlı yürümesini sağlayacaktır.
              <br />
              <br />
              Dilekçenin konusunun kısa ve anlamlı şekilde dilekçeye yazılması ilgili makamın işlerini hızlandırıp konuyu anlamasını kolaylaştıracağı için gereklidir.
              <br />
              <br />
              Dilekçe bir belgeye yanıt mahiyetinde yazılıyorsa ilgili belgeye mutlaka atıf yapılmalıdır. Yine talebinize/dilekçenize dayanak oluşturan belgeler varsa bunlar da dilekçenin sonuna yazılmalı ve dilekçe ile birlikte ilgili makama verilmelidir.
              <br />
              <br />
              Yukarıda bahsedilen bilgilerin doğru ve anlamlı şekilde girilmesi durumunda arzuhâlci konuya uygun ve girilen ayrıntıları da içeren bir dilekçeyi sizin için oluşturacaktır.
            </div>
          </IonAccordion>
          <IonAccordion value="fourth">
            <IonItem className='accordion-button-help' slot="header" color="light">
              <IonLabel className='accordion-button-label'>Arzuhâlci dilekçeyi oluşturdu. <br/> Sonra ne yapmalıyım?</IonLabel>
            </IonItem>
            <div className='accordion-content-help' slot="content">
              Dilekçede bulunması zorunlu unsurları arzuhalci’ye girdiniz, konuyu belirttiniz, konuyla ilgili ayrıntıları kısaca belirttiniz. Arzuhalci, yapay zekâsı yardımıyla dilekçe taslağını oluşturdu.
              <br />
              <br />
              Taslak dilekçe oluşturulduktan sonra ilk iş dilekçedeki -varsa- hatalı, tekrarlanmış, çelişkili ifadeler düzeltilmelidir. Sonrasında dilekçe düz beyaz kâğıda yazdırılmalıdır. Bunun için .pdf uzantılı belgeyi kullanabilir ya da metni kopyala/yapıştır yapabilirsiniz. (Dikkat! Metinde uygulama üzerinden değişiklik yaptığınız durumlarda .pdf uzantılı belgede bu değişiklikleriniz görünmeyecektir.)
              <br />
              <br />
              Tercihinize göre metni elle düz beyaz bir kâğıda da yazabilirsiniz. Böyle bir durumda mutlaka siyah ya da mavi mürekkepli kalem kullanılmalıdır. Yazma ya da yazdırma işlemi sırasında kâğıdın iki tarafı kullanılmamalıdır. Dilekçeye bir sayfaya sığmadıysa ikinci kâğıda geçilmelidir.
              <br />
              <br />
            </div>
          </IonAccordion>
          <IonAccordion value="fifth">
            <IonItem className='accordion-button-help' slot="header" color="light">
              <IonLabel className='accordion-button-label'>Arzuhâlci dilekçeyi oluşturdu ama <br/> ben imzalamayı unuttum. Ne olur?</IonLabel>
            </IonItem>
            <div className='accordion-content-help' slot="content">
              Böyle bir durumda -dilekçeyi verdiğiniz makama bağlı olarak- dilekçeniz herhangi bir biçimde kayda alınsa dahi geçersiz kabul edilecek ve beklenen hukuki sonucu doğurmayacaktır. Her dilekçe dilekçeyi veren kişi tarafından ıslak imza ile imzalanmalıdır.
            </div>
          </IonAccordion>
          <IonAccordion value="sixth">
            <IonItem className='accordion-button-help' slot="header" color="light">
              <IonLabel className='accordion-button-label'>Dilekçeyi ilgili kişiye/makama <br/> verirken neye dikkat etmeliyim?</IonLabel>
            </IonItem>
            <div className='accordion-content-help' slot="content">
              Özellikle devlet dairelerine verilecek dilekçelerde dilekçenin alındığına dair evrak numarası ve tarihi gösterir bir belge almayı ihmal etmeyin. Çok sayıda belge verilen bu dairelerde işlerin yürümesi ve takibi için bu numaranın bilinmesi zorunludur.
              <br />
              <br />
              Bu evrak takip numarası ve belge teslim tarihinin yazılabilmesi için dilekçenin ikinci bir örneğini yanınızda taşımanız lehinize olacaktır. Üzerinde tarih ve evrak kayıt numarası bulunan bu ikinci dilekçeyi kaybetmeyin.
            </div>
          </IonAccordion>
          <IonAccordion value="seventh">
            <IonItem className='accordion-button-help' slot="header" color="light">
              <IonLabel className='accordion-button-label'>Arzuhalcinin bir dilekçeyi <br/> yazması ne kadar sürer?</IonLabel>
            </IonItem>
            <div className='accordion-content-help' slot="content">
              İşin mahiyetine bağlı olarak değişmekle birlikte arzuhalci uygulamasının bir dilekçeyi yazması birkaç saniye ile birkaç dakika arasında sürebilmektedir. Uygulamanın dilekçeyi yazması 5 (beş) dakikanın üzerinde sürdüğü takdirde uygulamanın sahibi şirket ile bağlantıya geçerek sorun bildirebilirsiniz.
            </div>
          </IonAccordion>
        </IonAccordionGroup>

      </div>
    </div>
  );
};

export default Help;
