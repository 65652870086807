import "./PetitionCreatedDemo.css";
import { useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import {
  IonButtons,
  IonButton,
  IonModal,
  IonAlert,
  IonHeader,
  IonContent,
  IonToolbar,
  IonIcon,
  useIonLoading,
} from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import Disclaimer from "./Disclaimer";
import Parse from "parse";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import { Capacitor } from "@capacitor/core";

const PetitionCreatedDemo: React.FC = () => {
  const { petitionData } = useSelector((state: any) => state.slice);
  const { addressee, details } = petitionData;
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [infoIsOpen, setInfoIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [disclaimerIsOpen, setDisclaimerIsOpen] = useState(false);
  const [isLiveKeyEnabled, setIsLiveKeyEnabled] = useState(false);
  const [amountInfo, setAmountInfo] = useState<any>(null);
  const [showMore, setShowMore] = useState(0);
  const [paymentErrorText, setPaymentErrorText] = useState<any>(null);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [showLoading] = useIonLoading();

  const options: StripePaymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  };

  const handlePayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success`,
      },
    });
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setPaymentErrorText(result.error.message);
      setIsError(true);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const handleInAppPurchase = async () => {
    try {
      if (Capacitor.getPlatform() === "ios") {
        showLoading("Ödeme adımına geçiliyor, lütfen bekleyiniz...", 5000);
        const productToBuy = await Purchases.getProducts({ productIdentifiers: ["arzuhalci_1"] });
        const purchaseResult = await Purchases.purchaseStoreProduct({ product: productToBuy.products[0] });
        if (typeof purchaseResult.customerInfo.entitlements.active["arzuhalci_1"] !== "undefined") {
          console.log("User has access to arzuhalci_1");
          history.push("/payment-success");
        }
      } else if (Capacitor.getPlatform() === "android") {
        const productToBuy = await Purchases.getProducts({ productIdentifiers: ["arzuhalci_android"] });
        const purchaseResult = await Purchases.purchaseStoreProduct({ product: productToBuy.products[0] });

        if (typeof purchaseResult.customerInfo.entitlements.active["arzuhalci_android"] !== "undefined") {
          console.log("User has access to arzuhalci_android");
          history.push("/payment-success");
        }
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    (async function () {
      if (Capacitor.getPlatform() === "web") {
        return;
      } else {
        await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
        if (Capacitor.getPlatform() === "ios") {
          await Purchases.configure({
            apiKey: "appl_YDCMTVdkeUnyFVoMiAovpQbJyJy",
          });
        } else if (Capacitor.getPlatform() === "android") {
          await Purchases.configure({
            apiKey: "goog_GltRpKkNHkEOfHlYcSdMPINOQCd",
          });
        }
      }
    })();
  }, []);

  useEffect(() => {
    const demoPetitionsCreatedCount = localStorage.getItem(
      "demoPetitionsCreatedCount"
    );
    if (demoPetitionsCreatedCount) {
      localStorage.setItem(
        "demoPetitionsCreatedCount",
        (parseInt(demoPetitionsCreatedCount) + 1).toString()
      );
    } else {
      localStorage.setItem("demoPetitionsCreatedCount", "1");
    }
  }, []);

  useEffect(() => {
    async function fetchConfigs() {
      try {
        const query = new Parse.Query("Configs");
        query.equalTo("name", "liveKeysEnabled");
        const result = await query.first();
        if (result) {
          setIsLiveKeyEnabled(result?.get("value"));
        }
      } catch (error) {
        console.error("Error fetching configs:", error);
      }
    }
    fetchConfigs();
  }, []);

  useEffect(() => {
    async function fetchAmount() {
      try {
        const query = new Parse.Query("Configs");
        query.equalTo("name", "price");
        const result = await query.first();
        if (result) {
          setAmountInfo(result?.get("amount") / 100);
        }
      } catch (error) {
        console.error("Error fetching configs:", error);
      }
    }
    fetchAmount();
  }, []);

  return (
    <>
      <IonAlert
        isOpen={isError}
        header="Hata"
        message={`${
          paymentErrorText
            ? paymentErrorText
            : "Ödeme işlemi başarısız oldu. Lütfen tekrar deneyiniz."
        }`}
        buttons={["Tamam"]}
        onDidDismiss={() => setIsError(false)}
      ></IonAlert>

      <IonAlert
        isOpen={infoIsOpen}
        header="Bilgilendirme"
        message="Oluşturduğunuz dilekçe kaybolacaktır. Devam etmek istiyor musunuz?"
        buttons={[
          {
            text: "Hayır",
            role: "cancel",
            handler: () => {
              setInfoIsOpen(false);
            },
          },
          {
            text: "Evet",
            role: "confirm",
            handler: () => {
              history.push("/create-petition");
            },
          },
        ]}
      ></IonAlert>

      <IonModal
        className="stripe-modal-demo"
        isOpen={showStripeModal}
        onDidDismiss={() => setShowStripeModal(false)}
      >
        <PaymentElement className="card-info-create" options={options} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IonButton
            onClick={() => setShowStripeModal(false)}
            color="medium"
            className="text-center-button-demo "
            size="small"
          >
            Vazgeç
          </IonButton>
          <div className="stripe-connect" onClick={handlePayment}>
            <span>İle Öde</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <img
            className="stripe-badge-image"
            src="../assets/images/stripe-badge-grey.png"
            style={{ width: "40%", marginTop: "8px" }}
            alt="stripe-powered"
          />
        </div>
      </IonModal>

      <IonModal
        className="show-modal"
        isOpen={disclaimerIsOpen}
        onDidDismiss={() => setDisclaimerIsOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setDisclaimerIsOpen(false)}>
                Kapat
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <Disclaimer />
        </IonContent>
      </IonModal>

      <div className="container-created-demo">
        <div className="content-created-demo">
          <h1 className="label-created-demo">DİLEKÇENİZ</h1>
          <div className="petition-subject-created-demo">
            <p className="text-center-demo">{addressee}</p>
            <br />
            <br />
            {showMore === 0 ? (
              <span>{details?.slice(0, 100)}</span>
            ) : (
              <span>
                {" "}
                {details?.slice(0, Math.ceil(details?.length * 0.6))}
              </span>
            )}
            <span className="blur-text-demo">
              {showMore === 0
                ? details?.slice(100, details?.length)
                : details?.slice(
                    Math.ceil(details.length * 0.6),
                    details?.length
                  )}
            </span>
          </div>
          {isLiveKeyEnabled === true ? (
            <button
              onClick={() => {
                if (Capacitor.getPlatform() === "web") {
                  console.log("web");
                  setShowStripeModal(true);
                } else {
                  handleInAppPurchase();
                }
              }}
              disabled={!stripe}
              className="button-download-demo"
            >
              <span className="button-text-demo">SATIN AL</span>
              <span className="button-text-demo">{amountInfo} ₺</span>
            </button>
          ) : (
            <button
              onClick={() => history.push("/payment-success")}
              className="button-download-demo"
            >
              <span className="button-text-demo">ÖDEME ADIMINI ATLA</span>
            </button>
          )}
          {showMore === 0 && (
            <button
              onClick={() => setShowMore(showMore + 1)}
              className="button-download-demo"
            >
              <span className="button-text-demo">DAHA FAZLA GÖSTER</span>
            </button>
          )}
          <button
            onClick={() => setInfoIsOpen(true)}
            className="button-download-demo"
          >
            <span className="button-text-demo">YENİ DİLEKÇE OLUŞTUR</span>
            <IonIcon
              className="icon-demo"
              color="white"
              aria-hidden="true"
              icon={pencilOutline}
            />
          </button>
          <p className="text-center-disclaimer-demo mt-5">
            Arzuhalci , yapay zeka tarafından geliştirilen dilekçe örnekleri
            için sorumluluk kabul etmemektedir. Lütfen sorumluluk reddini{" "}
            <span
              className="strong-demo"
              onClick={() => setDisclaimerIsOpen(true)}
            >
              okuyunuz.
            </span>
          </p>
          {/* payment button will be added here */}
          {/* <button onClick={() => setShowModal(true)} disabled={!stripe} className="button-signature-demo">
            <span className="button-text-demo">İMZALA VE SATIN AL</span>
            <span className="button-text-demo">39.99 ₺</span>
          </button> */}
        </div>
      </div>
    </>
  );
};

export default PetitionCreatedDemo;
